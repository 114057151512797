import { BadgeFilter, BadgePosition } from '@futureecom/futureecom-js/dist/services/catalog-service'
import type { FilterDefinition } from '@/types/FilterDefinitions'

export const badgeFilters: FilterDefinition<BadgeFilter>[] = [
  { type: 'text', code: 'code', label: 'Code' },
  { type: 'text', code: 'text', label: 'Text' },
  {
    type: 'array',
    code: 'position',
    label: 'Position',
    options: [
      { text: 'Top left', value: BadgePosition.TOP_LEFT },
      { text: 'Top right', value: BadgePosition.TOP_RIGHT },
      { text: 'Bottom left', value: BadgePosition.BOTTOM_LEFT },
      { text: 'Bottom right', value: BadgePosition.BOTTOM_RIGHT },
      { text: 'Left center', value: BadgePosition.LEFT_CENTER },
      { text: 'Center center', value: BadgePosition.CENTER_CENTER },
      { text: 'Right center', value: BadgePosition.RIGHT_CENTER },
      { text: 'Top center', value: BadgePosition.TOP_CENTER },
      { text: 'Bottom center', value: BadgePosition.BOTTOM_CENTER }
    ]
  },
  { type: 'date', code: 'created_at', label: 'Created At' },
  { type: 'date', code: 'updated_at', label: 'Updated At' }
]
