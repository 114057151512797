import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({attrs:{"selected-items":_vm.selected},on:{"update:selectedItems":function($event){_vm.selected=$event},"update:selected-items":function($event){_vm.selected=$event},"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canCreate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'badge-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Add new badge'))+" ")]):_vm._e()]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'badge-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c(VIcon,[_vm._v(_vm._s(item.enabled ? 'done' : 'close'))])]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCodeToHumanText(item.position)))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'badge-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('badge-action-delete',{attrs:{"icon":"","value":item},on:{"success":_vm.onDeleteSuccess,"error":_vm.onRequestError}})]}},(_vm.canDelete)?{key:"grid.footer.actions",fn:function(){return [(_vm.errors)?_c('badge-action-force-mass-delete-dialog',{attrs:{"items":_vm.selected},on:{"success":_vm.onMassDeleteSuccess,"error":_vm.onRequestError},model:{value:(_vm.errors),callback:function ($$v) {_vm.errors=$$v},expression:"errors"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-gap-8"},[_c('badge-action-mass-delete',{attrs:{"items":_vm.selected,"disabled":_vm.grid.loading || !_vm.selected.length},on:{"success":_vm.onMassDeleteSuccess,"error":function($event){_vm.errors = $event}}}),_c('button-action',{attrs:{"label":_vm.$t('Force delete'),"items":_vm.selected,"text":"","disabled":_vm.grid.loading || !_vm.selected.length},on:{"click":function($event){_vm.errors = { message: _vm.$t('Confirm force delete') }}}})],1)]},proxy:true}:null],null,true)},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Badges'), gridProps: { showSelect: _vm.canDelete } },false))}
var staticRenderFns = []

export { render, staticRenderFns }